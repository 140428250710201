import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Input, Pagination, Select, Spin } from "antd";
import axios from 'axios';
import './style.facilitystatistics.scss'
import ResultAPI from '../../../api/results/results.api';
import SYSTEM_CONSTANTS from '../../../common/constants';
import RadarAmChart from '../../../components/Chart/RadarAmChart';
import RadarAmChartSingle from '../../../components/Chart/RadarAmChartSingle';
import { IFacilities } from '../../../common/u-innovate/define-facilities';
import FacilitiesAPI from '../../../api/facilities/facilities.api';

const FacilityStatistics = () => {
    const [yearSelected, setYearSelected] = useState<string>('2024')
    const [facilitySelected, setFacilitySelected] = useState<string>('')
    const [facilities, setFacilities] = useState<IFacilities[]>([])
    const [data, setData] = useState<any[]>([])
    const [data1, setData1] = useState<any>(null)
    const [lstChartData, setLstChartData] = useState<Array<{
        name: string;
        value1: number;
        value2: number;
        value3: number;
    }>>([]);
    const [singleChartData, setSingleChartData] = useState<Array<{ category: string, value: number }>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const initializeFacilities = async () => {
            try {
                await axios.get(`${FacilitiesAPI.host}/${SYSTEM_CONSTANTS.API.FACILITIES.GET_ALL}`)
                    .then((res) => {
                        const facilitiesList = res.data.data;
                        setFacilities(facilitiesList);
                        // Tự động chọn cơ sở đầu tiên
                        if (facilitiesList && facilitiesList.length > 0) {
                            setFacilitySelected(facilitiesList[0].id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } catch (error) {
                console.error(error);
            }
        };

        initializeFacilities();
    }, []);

    useEffect(() => {
        if (facilitySelected) {
            fetchData();
        }
    }, [yearSelected, facilitySelected])

    useEffect(() => {
        if (!data1 || !data) return;

        let lstName: any[] = [];
        let lstValue1: any[] = [];
        let lstValue2: any[] = [];

        // Lấy dữ liệu từ longform
        if (data1.statisticOfFacilityLong) {
            lstName = data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => item.criteriaName);
            lstValue1 = data1.statisticOfFacilityLong.listAverageOfCriterias.map((item: any) => 
                Math.round(item.averageOfCriteria * 10) / 10
            );
        }

        // Lấy dữ liệu từ shortform 
        if (data1.statisticOfFacilityShort) {
            if (!data1.statisticOfFacilityLong) {
                lstName = data1.statisticOfFacilityShort.listAverageOfCriterias.map((item: any) => item.criteriaName);
            }
            lstValue2 = data1.statisticOfFacilityShort.listAverageOfCriterias.map((item: any) =>
                Math.round(item.averageOfCriteria * 10) / 10
            );
        }


        const chartData: any = lstName.map((name: any, index: any) => ({
            name,
            value1: lstValue1[index] || 0,
            value2: lstValue2[index] || 0,
        }));

        setLstChartData(chartData);
    }, [data1, data]);

    const fetchDataSystemStatistics = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        try {
            const response = await axios.get(
                `${ResultAPI.host}/administrators/statistical-long-short-admin/${facilitySelected}/${yearSelected}`,
                {
                    headers: {
                        Authorization: `Bearer ${checkLogin}`,
                        'accept': 'application/json'
                    }
                }
            );
            console.log('response', response.data.data);
            
            if (response.data.data) {
               
                setData(response.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const fetchNumberUser = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        try {
            const response = await axios.get(
                `${ResultAPI.host}/administrators/statistical-long-short-admin/${facilitySelected}/${yearSelected}`,
                {
                    headers: {
                        Authorization: `Bearer ${checkLogin}`,
                        'accept': 'application/json'
                    }
                }
            );
            if (response.data.data) {
                setData1(response.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getTottalUser = () => {
        let total = 0;
        if (data1?.statisticOfFacilityLong?.totalOfResult) {
            total += data1.statisticOfFacilityLong.totalOfResult;
        }
        if (data1?.statisticOfFacilityShort?.totalOfResult) {
            total += data1.statisticOfFacilityShort.totalOfResult;
        }
        return total;
    }

    const fetchData = async () => {
        setIsLoading(true);
        try {
            await fetchDataSystemStatistics();
            await fetchNumberUser();
        } finally {
            setIsLoading(false);
        }
    }

    // Thêm hàm filter cho Select
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    // Chuẩn bị options cho Select
    const facilityOptions = facilities.map(facility => ({
        label: facility.name,
        value: facility.id
    }));

    return (
        <Spin spinning={isLoading}>
            <div className='main-statistics'>
                <div className='facility-statistics'>
                    <div className='statistics-title'>
                        <h5>Thống kê theo cơ sở</h5>
                    </div>

                    <div className='statistics-filter'>
                        <Select
                            placeholder="Chọn năm"
                            defaultValue={'2024'}
                            onChange={(value) => setYearSelected(value)}
                            style={{ width: 120 }}
                        >
                            <Select.Option value="2023">2023</Select.Option>
                            <Select.Option value="2024">2024</Select.Option>
                        </Select>

                        <Select
                            placeholder="Chọn cơ sở"
                            value={facilitySelected}
                            onChange={(value) => setFacilitySelected(value)}
                            style={{ width: 300 }}
                            showSearch
                            filterOption={filterOption}
                            options={facilityOptions}
                            allowClear
                        />
                    </div>

                    {facilitySelected && (
                        <>
                            <div className="total-stats">
                                <div className="stat-item">
                                    <span>Tổng số người đã tham gia đánh giá:</span>
                                    <b>{getTottalUser()}</b>
                                </div>
                                {data1?.statisticOfFacilityLong?.totalOfResult > 0 && (
                                    <div className="stat-item">
                                        <span>Số lượng đánh giá longform:</span>
                                        <b>{data1.statisticOfFacilityLong.totalOfResult}</b>
                                    </div>
                                )}
                                {data1?.statisticOfFacilityShort?.totalOfResult > 0 && (
                                    <div className="stat-item">
                                        <span>Số lượng đánh giá shortform:</span>
                                        <b>{data1.statisticOfFacilityShort.totalOfResult}</b>
                                    </div>
                                )}
                            </div>

                            <div className='charts'>
                                <div className="chart-container">
                                    {lstChartData.length > 0 && (
                                        <div className="radar-amchart">
                                            <RadarAmChart lstChart={lstChartData} />
                                            <div className='labels'>
                                                <div className='labels__item'>
                                                    <div className='labels__item__color' 
                                                        style={{ backgroundColor: '#6794dc' }}/>
                                                    <div className='labels__item__text'>
                                                        Trung bình longform
                                                    </div>
                                                </div>
                                                <div className='labels__item'>
                                                    <div className='labels__item__color' 
                                                        style={{ backgroundColor: '#67dc94' }}/>
                                                    <div className='labels__item__text'>
                                                        Trung bình shortform
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Spin>
    )
}

export default FacilityStatistics 
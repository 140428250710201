import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Home from '../pages/home/Home'
import AboutUs from '../pages/AboutUs/AboutUs'
import JudgementMain from '../pages/Judgement/JudgementMain'
import Login from '../pages/login/Login'
import { AnimatePresence } from 'framer-motion'
import News from '../pages/news/News'
import ActiveAccount from '../pages/ActiveAccount/ActiveAccount'
import Result from '../pages/Judgement/Result'
import PrivacyPolicy from '../pages/PrivacyLegal/PrivacyPolicy'
import LegalNotice from '../pages/PrivacyLegal/LegalNotice'
import { useDispatchRoot, useSelectorRoot } from '../redux/store'
import OverviewAdmin from '../pages/Admin/overview/OverviewAdmin'
import UserManagement from '../pages/Admin/userManagement/UserManagement'
import FacilityManagement from '../pages/Admin/facilityManagement/FacilityManagement'
import UserStatistics from '../pages/User/UserStatistics'
import FacilityStatistics from '../pages/Admin/facilityManagement/FacilityStatistics'

// Dùng để set animation cho các router với nhau
const AnimationRouter = () => {
    const location = useLocation();
    const dispatch = useDispatchRoot();
    const { user } = useSelectorRoot((state) => state.login);
    const navigate = useNavigate();

    // Kiểm tra xem đường dẫn đang là gì để set thuộc tính đã click cho header
    useEffect(() => {
        console.log(window.location.pathname);
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (window.location.pathname === '/user-statistics' || window.location.pathname === '/active/' || window.location.pathname === '/active') {
            return;
        }
        console.log(user);
        if (window.location.pathname === '/') {
            if (user && user.role === 'admin') {
                navigate('/user-management');
            }
            else if (user && user?.role !== 'admin') {
                navigate('/home');
            }
        }
        if (user && user.role === 'admin') {
            if (window.location.pathname !== '/admin' && window.location.pathname !== '/user-management' && window.location.pathname !== '/year-management' && window.location.pathname !== '/facility-statistics') {
                navigate('/user-management');
                return;
            }
        }
        else if (user && user.role === 'user') {
            if (window.location.pathname !== '/home' && window.location.pathname !== '/about_us' && window.location.pathname !== '/test' && window.location.pathname !== '/new' && window.location.pathname !== '/login' && window.location.pathname !== '/privacy-policy' && window.location.pathname !== '/legal-notice') {
                navigate('/home');
                return;
            }
        }
        else {
            if (window.location.pathname !== '/home' && window.location.pathname !== '/about_us' && window.location.pathname !== '/test' && window.location.pathname !== '/new' && window.location.pathname !== '/login' && window.location.pathname !== '/privacy-policy' && window.location.pathname !== '/legal-notice') {
                navigate('/home');
                return;
            }
        }
    }, [window.location.pathname, user])

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/about_us" element={<AboutUs />}></Route>
                <Route path="/test" element={<JudgementMain />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/new" element={<News />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="/legal-notice" element={<LegalNotice />}></Route>
                <Route path="/admin" element={<OverviewAdmin />}></Route>
                <Route path="/user-statistics" element={<UserStatistics />}></Route>
                <Route path="/user-management" element={<UserManagement />}></Route>
                <Route path="/year-management" element={<FacilityManagement />}></Route>
                <Route path="/facility-statistics" element={<FacilityStatistics />}></Route>
                <Route path="*" element={<ActiveAccount />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimationRouter